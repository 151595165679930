import React,{useEffect } from "react";
import { Box } from '@mui/material';
import Card from '../Card/Card'
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { PortfolioData } from '../../assets/info/ProtfolioData';
import PortfolioStyle from './portfolio.module.scss'
import ContactForm from '../Contact/ContactForm'
import AOS from "aos";
import "aos/dist/aos.css";

import { useMediaQuery } from '@mui/material';


const PortfolioKeys = Object.keys(PortfolioData);


export default function Portfolio(props) {
  window.scrollTo(0, 0)
  let darkMode = JSON.parse(localStorage.getItem('darkMode'));
  
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  console.log("darkMode==1=>>",darkMode)
  console.log("darkMode==2=>>",props.darkMode)

  useEffect(() => {
    AOS.init();
  }, []);

  return (

    <Box
      mt={'2rem'}
      className = {PortfolioStyle.mainPortfolioDiv}
    >

{PortfolioKeys.map((PortfolioKey, index) => (
    <React.Fragment key={index}>
      <h2 data-aos="fade-right" data-aos-duration="1500">{PortfolioKey}</h2>
      <Timeline className='portfolioDiv'
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: isSmallScreen ? '5px' : '9%' ,
        },
      }}
      >
            {PortfolioData[PortfolioKey].map((ProjectsData, index) => (
              
                <TimelineItem key={index} position="right">
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent > 
                    <Card 
                      darkMode        = {darkMode}
                      Link            = {ProjectsData.Link}
                      ProjectInitials = {ProjectsData.ProjectInitials}
                      PortfolioPic    = {ProjectsData.PortfolioPic}
                      CardColor       = {ProjectsData.CardColor }
                      Title           = {ProjectsData.Title}
                      Subheader       = {ProjectsData.Subheader}
                      Body            = {ProjectsData.Body}
                      Paragraph       = {ProjectsData.Paragraph}
                      key             = {index}
                    /> 
                </TimelineContent>
              </TimelineItem>
            ))}

         
      
    </Timeline>

   </React.Fragment>

  ))}


    <ContactForm darkMode={darkMode}  />


     </Box>
  );
}
