import React from 'react'
import Marquee from "react-fast-marquee";
import './Skills.scss'; 
import SkillsSet from '../../assets/info/SkillsSet'


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}


let allSkillsLogos = Object.values(SkillsSet).flatMap(skills => skills.filter(skill => skill.showInSlider)).map(skill => skill.logo);
allSkillsLogos = shuffleArray(allSkillsLogos)

function SkillsSliderV2() {
  return (
    <div 
    style={{
      width: '80%', 
      // backgroundColor: '#481b9250',
      alignSelf: 'center'
    
    }}
    >
        <Marquee speed={40}>
            {allSkillsLogos.map((skillLogo, index) => (
                <img src={skillLogo} alt={index} key={index} 
                style={{
                  width   : 'auto',
                  height  : '70px',
                  padding : '20px',
                  paddingLeft : '30px'
                }}
                />
              ))}
        </Marquee>
      </div>
  )
}

export default SkillsSliderV2



