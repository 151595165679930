import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';


import CardStyle from './card.module.scss'
import CodeIcon from '@mui/icons-material/Code';
import {CardBackgraoudColors , CardShadowColors , CardBorderColors } from '../../assets/colors/AllColors'

import { useMediaQuery } from '@mui/material';



const ExpandMore = styled((props) => {
let { expand, ...other } = props;
console.log("==expand===",props);
return <h1>Hey</h1>
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // marginLeft: 'auto',
  // transition: theme.transitions.create('transform', {
  //   duration: theme.transitions.duration.shortest,
  // }),
}));

export default function CardItems(props) {
  let [expanded, setExpanded] = React.useState(false);
  let textColor     = props.darkMode ? 'white' : 'black';
  let CardColor     = props.CardColor ;
  let PortfolioPic  = props.PortfolioPic ;

  let handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let isSmallScreen = useMediaQuery('(max-width:700px)');


  return (
    <Card className={CardStyle.Card} 
          sx={{ 
              // bgcolor: 'transparent' , 
              backgroundColor : props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 

              boxShadow: props.darkMode ? CardShadowColors[0] : CardShadowColors[1], 
              border   : props.darkMode ? CardBorderColors[0] :CardBorderColors[1]
              }} >
      <CardHeader
        avatar={
          <Avatar sx={{backgroundColor:'#8b33ce'}} > <CodeIcon /> </Avatar>
        }
        title= {<h3>{props.Title}</h3>} 
        // subheader={props.Subheader}

        className={CardStyle.CardDetails}
        style={{  
          // backgroundColor : CardColor ,
          // backgroundColor : props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 

          color :  textColor,
          fontWeight:'bold' ,
          fontSize : '25px'
        }}
      />
      
      {props.Link?(
          <a target="_blank" rel="noopener noreferrer" href={props.Link}>
            <CardMedia component="img" height="190" image={PortfolioPic} alt="Paella dish" className={CardStyle.CardMediaPic} />
          </a>
          ):(
          <CardMedia component="img" height="190" image={PortfolioPic} alt="Paella dish"  />
          )
      }
      
      <CardContent  className={CardStyle.CardDetails}  
       style={{  
        // backgroundColor: CardColor ,
        // backgroundColor : props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
        color :  textColor 
        }} >
        {props.Body && props.Body.map((BodyData, index) => (
             <Typography  key={index} sx={{fontSize: isSmallScreen ? '11px' : '16px'}} >
              <br/>
              {index ? ( <span style={{color :'red'}}>* </span> ) : ("")}
              {BodyData}
           </Typography>
          ))}       
      </CardContent>





      <CardActions disableSpacing  className={CardStyle.CardDetails}  
          style={{  
            color :  textColor 
            }} >

        {/* <ExpandMore
          // expand={expanded}
          // onClick={handleExpandClick}
          // aria-expanded={expanded}
          // aria-label="show more"
        > */}

          <UnfoldMoreIcon style={{ 
            color : 'white', 
            fontWeight : 'bold' , 
            backgroundColor : '#8b33ce' ,
            borderRadius : '50px',
            width  : '30px',
            height : '30px',
            marginLeft: 'auto'

            }} 
            
          onClick={handleExpandClick}
            
            />

        {/* </ExpandMore> */}

      </CardActions>
     



      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent  className={CardStyle.CardDetails}  
          style={{  
            // backgroundColor: CardColor , 
            color :  textColor 
            }} >
          <h4> Insights into My Work Contributions:<br/><br/></h4>

          {props.Paragraph && props.Paragraph.map((ParagraphData, index) => (
            <Typography paragraph key={index} sx={{fontSize: isSmallScreen ? '11px' : '16px'}} >
             <span style={{color :'red'}}>* </span> 
              {ParagraphData}
            </Typography>
          ))}
        </CardContent>
      </Collapse>


    </Card>
  );
}
