import axios from 'axios';

const Telegram_API_key = "6024844082:AAGkwmG6ExQIt3FD2_0GHy6iN2R4OvREmpo";
const TelegramBaseURL = `https://api.telegram.org/bot${Telegram_API_key}/sendMessage`;

const TelegramChatID = "520475688"

export async function sendTelegramMessage(opts) {
  try {
    let reqBody = {
      chat_id: TelegramChatID ,
      text: opts.message, 
    }
    await axios.post(TelegramBaseURL,reqBody );
  } catch (error) {
    throw new Error('Error occurred in sendTelegramMessage: ' + error);
  }
}


