import React, { useState ,useEffect } from 'react';
import Style from './Navbar.module.scss';
import Toggler from '../home/Toggler';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';

import { particleBgColors } from '../../assets/colors/AllColors';
let { background } = particleBgColors();

const links = [
  {
    name: 'Home',
    to: '/',
    active: 'home',
  },
  {
    name: 'About',
    to: '/about',
    active: 'about',
  },
  {
    name: 'Portfolio',
    to: '/portfolio',
    active: 'portfolio',
  },
  {
    name: 'Skills',
    to: '/skills',
    active: 'skills',
  },
];

export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === '/' ? 'home' : location.pathname.slice(1, location.pathname.length),
  );

  let [backgroundColor, setBgColor] = useState(background(darkMode));
  useEffect(() => { 
    setBgColor(background(darkMode));
  }, [darkMode, background]);


  const logo = {
    name: info.initials,
    type: 'initials',
    to: '/',
    active: 'home',
  }

  return (
    <Box component={'nav'} 
      style={{ backgroundColor :  backgroundColor,
      zIndex : 1000,
      position: "fixed", 
      top: '0px',
      left: '0px',
      width: '100%',
  }}
      >
      <Box
        component={'ul'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={{ xs: '1rem', md: '8rem' }}
        fontSize={'1rem'}
      >
        <Box sx={{ border: 2 , borderRadius : '13px', margin:'5px' }} >
           <Link to={logo.to} onClick={() => setActive(logo.active)} className={Style.link}>
              {!logo.type && <p style={{padding: '0.5rem 0'}}>{logo.name}</p>}
              {logo.type && <h1>{logo.name}</h1>}
            </Link>
        </Box>

        {links.map((link, index) => (
          <Box
            key={index}
            component={'li'}
            className={link.active === active && !link.type && Style.active}
            // sx={{ borderImageSource: info.gradient }}
            sx={{ borderImageSource: info.NavGradient  }}
          >
            <Link to={link.to} onClick={() => setActive(link.active)} className={Style.link}>
              {!link.type && <p className={Style.navName}>{link.name}</p>}
              {link.type && <h1>{link.name}</h1>}
            </Link>
          </Box>
        ))}
        <span>
          <Toggler darkMode={darkMode} handleClick={handleClick} />
        </span>
      </Box>
    </Box>
  );
}
