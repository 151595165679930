import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import Typography from '@mui/material/Typography';
import TimelineStyle from './Timeline.module.scss'

import {CardBackgraoudColors , CardShadowColors,CardBorderColors} from '../../assets/colors/AllColors'
import { useMediaQuery } from '@mui/material';


function CustomizedTimelineItems(props) {
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  return (
        <TimelineItem  >
          { isSmallScreen ? "" :
          <TimelineOppositeContent sx={{ m: 'auto 0' ,}} >
           <h4 >{props.dateTime}</h4>
          </TimelineOppositeContent>
          }
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot style={{backgroundColor : '#760cdf8a' }}>
              <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: '15px', px: 2 }}>
             
              <Typography variant="h6" component="span" >
                  <div className={TimelineStyle.timelineTitle} 
                  style={{
                    backgroundColor : props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
                    // border          : props.darkMode ? CardBorderColors[0] :   CardBorderColors[1],
                    // boxShadow       : props.darkMode ? CardShadowColors[0] : CardShadowColors[1], 

                  }}
                  >
                    <h4>{props.title}</h4>
                  </div>
              </Typography>
             
              <Typography component="span"  >
                <div className={TimelineStyle.TimelineItemDiv} 
                style={{
                  backgroundColor : props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
                  border          : props.darkMode ? CardBorderColors[0]     : CardBorderColors[1],
                  boxShadow       : props.darkMode ? CardShadowColors[0]     : CardShadowColors[1], 
                }}
                >

                  {isSmallScreen ? <p > <span style={{color :'red'}}>* </span> {props.dateTime} <br/><br/> </p>  : ""}

                  {props.description.map((description, index) => (
                    <p key={index}>
                      <span style={{color :'red'}}>* </span> {description} <br/><br/>
                    </p>
                  ))}
                </div>
              </Typography>
          </TimelineContent>
        </TimelineItem>
  )
}

export default CustomizedTimelineItems
