import React, { useState, useRef, useEffect } from 'react';
import './ContactStyle.scss';

const sendTelegramMessage = require('../../SendRequest/Axios').sendTelegramMessage;

import {CardBackgraoudColors , CardShadowColors,CardBorderColors} from '../../assets/colors/AllColors'



const ContactForm = ( {darkMode }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Added state for success message
  const formRef = useRef(null);

  const handleOpenForm = () => {
    setIsOpen(true);
  };

  const handleCloseForm = (opts) => {
    setTimeout(() => {
      setIsOpen(false);
      setEmail('');
      setPhoneNumber('');
      setMessage('');
    }, opts && opts.time || 0);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let Message= `************************
                  \nPhone Number : ${phoneNumber}
                  \nEmail    : ${email}
                  \nMessage  : ${message}
                  \n***********************`
    sendTelegramMessage({message : Message })

    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);

    handleCloseForm({ time: 1000 });
  };

  const handleClickOutsideForm = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      handleCloseForm();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutsideForm);
    } else {
      document.removeEventListener('click', handleClickOutsideForm);
    }

    return () => {
      document.removeEventListener('click', handleClickOutsideForm);
    };
  }, [isOpen]);

  return (
    <div className="contact-form-wrapper">
      {!isOpen && (
        <div className="floating-icon" onClick={handleOpenForm}>
          {/* Add icon here if any*/}
          <i className="fa fa-paper-plane" aria-hidden="true"></i>
        </div>
      )}
      {isOpen && (
        <div className="contact-form-container" ref={formRef} 
         style={{
          backgroundColor : darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
          border          : darkMode ? CardBorderColors[0] :   CardBorderColors[1],
          boxShadow       : darkMode ? CardShadowColors[0] : CardShadowColors[1], 

         }}
         >
          {showSuccessMessage && (
            <div className="success-message">
              <p>Thank you for your message. I will get back to you soon.</p>
              {/* <img src="success-image.png" alt="Success" /> */}
            </div>
          )}
          <form className="contact-form" onSubmit={handleSubmitForm} >
            <h2>Contact Me</h2>
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              maxLength={13}
              minLength={10}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{height : '100px'}}
            ></textarea>
            <button type="submit">Send</button>
            
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
