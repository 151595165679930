import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import CustomizedTimelineItems from './CustomizedTimelineItems';
import TimelineStyle from './Timeline.module.scss'
import { useMediaQuery } from '@mui/material';

import { timelineItemClasses } from '@mui/lab/TimelineItem';



export default function CustomizedTimeline(props) {
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  return (
      <Timeline 
      position= {isSmallScreen? 'right' : 'alternate'}
      className={TimelineStyle.TimelineDiv} 
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: isSmallScreen ? '5px' : '9%' ,
        },
      }}
      >
         {props.TimelineArray.map((journeyData, index) => (
            <CustomizedTimelineItems 
              dateTime    = {journeyData.dateTime }  
              title       = {journeyData.title } 
              description = {journeyData.description}
              key = {index}
              darkMode = {props.darkMode}
            />
          ))}
      </Timeline>
  );
}