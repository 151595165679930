const defaultDarkMode = false ; 

export let colors = ['rgb(0,255,164)', 'rgb(166,104,255)'];
export const NavBorderColors = ['#9a81f1', '#9a81f1'];

// original
export const particleBgColors = () => {
  // for dark use atom one dark, for light use CLRS
  const background = (darkMode = false) => (darkMode ? '#0d2538' : '#DCD6C8');
  const particle = (darkMode = false) => (darkMode ? '#c678dd' : '#5C7C8A');
  const links = (darkMode = false) => (darkMode ? '#6A6EAB' : '#DDB77A'); //98c379 //E7B669
  
  return {
    background,
    particle,
    links,
  };
};

// Original Colors
export const CardBackgraoudColors = ['rgba(0, 0, 0, 0.3)'  , 'rgba(255, 255, 255, 0.3)'];
export const CardShadowColors     = ['0 0 10px 0 rgba(0, 0, 0, 0.3)' , '0 0 10px 0 rgba(255, 255, 255, 0.3)' ];
export const CardBorderColors     = ['0px solid #5a0fafae'  , '0px solid #5a0fafae'];

