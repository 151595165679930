import React from 'react'
import Style from './Resume.module.scss';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';

function DownloadResume() {
  return (
    <Box
      display={'flex'}
      gap={'1.5rem'}
      justifyContent={'center'}
      fontSize={{ xs: '1.5rem', md: '2rem' }}
    >
      {/* <a href={info.documents[0].link} download="Vikram_CV.pdf" target="_self" className={Style.buttonDownload}>
          RESUME
      </a> */}
       <a  className={Style.buttonDownload} onClick={() => {
    alert('Resume download is temporary disabled!');
  }}>
          RESUME
      </a>

    </Box>
   
  )
}

export default DownloadResume
