import SkillsItems from "./SkillsItems";
import { Box } from '@mui/material';
import SkillsSet from '../../assets/info/SkillsSet'
import ContactForm from '../Contact/ContactForm'
import { green } from "@mui/material/colors";

const SkillsSetArea = Object.keys(SkillsSet);

function Skills({darkMode}) {
  window.scrollTo(0, 0)

  return (
    <Box 
      display={'flex'} 
      flexDirection={'column'} 
      alignItems={'center'} 
      mt={'3rem'}
    >
    
         {SkillsSetArea.map((SkillsData, index) => (
            <SkillsItems
             Title = {SkillsData}
             SkillsArray = {SkillsSet[SkillsData]}
             key = {index}
             darkMode ={darkMode}
            />

          ))}


    <ContactForm darkMode={darkMode}  />
    </Box>
  );
}

export default Skills;
