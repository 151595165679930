import self from '../img/self.png';
import { colors ,NavBorderColors } from '../colors/AllColors';
import MyCV from '../pdf/Vikram_CV.pdf'

export const info = {
  firstName: 'Vikram',
  lastName: 'Sahani',
  initials: 'VS',
  position: ['Backend Developer','Team Lead','Sr. Software Engineer'],

  selfPortrait: self, 
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, 
  NavGradient: `-webkit-linear-gradient(135deg, ${NavBorderColors})`, 
  baseColor: colors[0],
  miniBio: [
    {
      emoji: '💻',
      text: "Senior Software Engineer at Jungleworks.",
    },
    {
      emoji: '📧',
      text: <a href="mailto:vikramsahani@pm.me">vikramsahani@pm.me</a> 
    },
    {
      emoji: '🌍',
      text: 'Gorakhpur, Uttar Pradesh, India',
    },
    
   
  ],
  documents: [
    {
      link: MyCV,
      icon: 'fa fa-file-pdf-o',
      label: 'resume',
    },
  ],
  socials: [
   
    {
      link: 'https://www.instagram.com/vikramsahani256/',
      icon: 'fa fa-instagram',
      label: 'instagram',
    },
    {
      link: 'tg://msg?to=@vikramsahani',
      icon: 'fa fa-telegram',
      label: 'telegram',
    },
    {
      link: 'https://github.com/vikramsahani256',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/vikramsahani256/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
    {
      link: 'https://twitter.com/_vikramsahani',
      icon: 'fa fa-twitter',
      label: 'twitter',
    },
    
  ],
};
