import React from 'react';
import Style from './Home.module.scss';
import me from '../../assets/img/self.png';
import classNames from 'classnames';
import EmojiBullet from './EmojiBullet';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';
import DownloadResume from '../Resume/DownloadResume';
import ContactForm from '../Contact/ContactForm'
import TypedText from '../../components/TypedText/TypedText'

import {CardBackgraoudColors , CardShadowColors , CardBorderColors} from '../../assets/colors/AllColors'

// width: {
//   xs: 100, // 0
//   sm: 200, // 600
//   md: 300, // 900
//   lg: 400, // 1200
//   xl: 100, // 1536
//   },


export default function Home({ darkMode }) {

  window.scrollTo(0, 0)

  return (
    <Box
      component={'main'}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={'center'}
      justifyContent={'center'}
      // minHeight={'calc(100vh - 175px)'}
      sx={{
        marginTop : '7%',
      }}
    >
      <Box
        className={classNames(Style.avatar, Style.shadowed)}
        alt={'My Image'}
        style={{ background: info.gradient, objectFit: 'cover' }}
        component={'img'}
        src={me}
        width={{ xs: '30vh', md: '45vh' }}
        height={{ xs: '30vh', md: '45vh' }}
        borderRadius="50%"
        p={'0.25rem'}
        margin={'1.5rem'}
      />
      <Box
        style={{
          borderRadius        : '1rem',
          backdropFilter      : 'blur(3px)',
          WebkitBackdropFilter: 'blur(3px)',
          padding             : '1rem 1.5rem',
          transition          : 'all 0.3s ease',
          border              : darkMode ? CardBorderColors[0] : CardBorderColors[1],
          background          : darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
          boxShadow           : darkMode ? CardShadowColors[0] : CardShadowColors[1], 
        }}
        className = 'detailsBox'
      >
        <h1> Hi, I'm{' '}
          <span
            style={{
              background: info.gradient,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {info.firstName +" "+ info.lastName}
          </span>
          <span className={Style.hand}>🤚</span>
        </h1>
        
      
        <h2 style={{ textAlign: "left" }}> 👨‍💻 <TypedText TextArray={info.position} /></h2>
   
        
        <Box component={'ul'} 
        // p={'0.8rem'}
        >
          {info.miniBio.map((bio, index) => (
            <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
          ))}
        </Box>

        <DownloadResume/>
      
      </Box>
      <ContactForm darkMode={darkMode}  />
    </Box>
    
  );
}
