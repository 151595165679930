import { useState, useEffect } from "react";
import "./Skills.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Box , Rating , Stack} from '@mui/material';
import {CardBackgraoudColors , CardShadowColors,CardBorderColors} from '../../assets/colors/AllColors'
import StarIcon  from '@material-ui/icons/Star';
import { useMediaQuery } from '@mui/material';


function getDirection(index) {
  return index % 2 === 0 ? "left" : "right";
}

function getAOSDirection(index) {

  return index % 2 === 0 ? "fade-up-right" : "fade-up-left";
}

function SkillsItems(props) {
  let [SkillsArray, setSkillsArray] = useState([]);
  
  useEffect(() => {
    AOS.init();
    SkillsArray = props.SkillsArray;
    setSkillsArray(SkillsArray);
  }, []);

  const isSmallScreen = useMediaQuery('(max-width:700px)');

  return (
    <Box className="manual-container">
      <div className="SkillsSetTitle">
        <h1 data-aos="fade-up" data-aos-duration="1500" >
          {props.Title}
        </h1>
      </div>

      {SkillsArray.map((item, index) => {
        return (
          <div
            key={index}
            align={getDirection(index)}
            data-aos={isSmallScreen ? "fade-up" : getAOSDirection(index)}
            data-aos-duration="1000"
          >
            <div className="card-management" key={index} 
             style={{
              backgroundColor: props.darkMode ? CardBackgraoudColors[0] : CardBackgraoudColors[1], 
              boxShadow      : props.darkMode ? CardShadowColors[0]     : CardShadowColors[1]    , 
              border: props.darkMode ? CardBorderColors[0] :CardBorderColors[1],

             }}
            >
              <div className="card-management-inner">
                  <div className="card-management-title" ><h2>{item.title}</h2></div>
                  <div className="card-management-rating" >
                    <Stack spacing={1}>
                      <Rating 
                        name="half-rating-read" 
                        defaultValue={item.rating||0} 
                        precision={0.5}  
                        emptyIcon={<StarIcon style={{ color : props.darkMode ? '#ffffff50' : '#00000050' }} />} 
                        readOnly />
                    </Stack>
                  </div>
              </div>
             
              <div className="card-management-logo"><img src={item.logo} /></div>
            </div>
          </div>
        );
      })}
    </Box>
  );
}

export default SkillsItems;
