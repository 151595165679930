import React, {  useEffect, useState } from 'react';
import FooterStyle from './FooterStyle.scss';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';
import SocialIcon from '../home/SocialIcon';


function Footer() {

  return (
    <Box
    component={'footer'}
    pb={{xs  : '1rem', sm : '0'}}
    >
       <Box
          display={'flex'}
          gap={'2rem'}
          justifyContent={'center'}
          fontSize={{ xs: '1.5rem', md: '2rem' }}
        >
          {info.socials.map((social, index) => (
            <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
          ))}
        </Box>

          <Box
            component={'footer'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            py={'0.6rem'}
            sx={{ opacity: 0.7 }}
            width={'100%'}
          >
            <h4>2023 &copy; vikramsahani.in All Rights Reserved</h4>
          </Box>
    </Box>
  )
}

export default Footer
