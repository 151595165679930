import React, { useEffect } from "react";
import { Box } from '@mui/material';
import CustomizedTimeline from '../Timeline/CustomizedTimeline';
import ContactForm from '../Contact/ContactForm'
import AboutStyle from './About.module.scss'
import timelineConstant from '../../assets/info/timelineConstant';
import AOS from "aos";
import "aos/dist/aos.css";

const timelineKeys = Object.keys(timelineConstant);

function About({ darkMode }) {
  window.scrollTo(0, 0)

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <Box
    display={'flex'} 
    flexDirection={'column'} 
    alignItems={'center'} 
    mt={'3rem'}
    > 

   {timelineKeys.map((timelineData, index) => (
          <React.Fragment key={index}>
            <div className={AboutStyle.SkillsSetTitle}>
              <h2 data-aos="fade-right" data-aos-duration="1500" > {timelineData} </h2>
            </div>
            <CustomizedTimeline 
              TimelineArray = {timelineConstant[timelineData]}
              key = {index}
              darkMode = {darkMode}
            />
            </React.Fragment>
          ))}
    <ContactForm darkMode={darkMode}  />
    </Box>
  )
}

export default About



