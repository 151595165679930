
import tookanPic from '../../assets/img/Portfolio/dms.jpg'
import yeloPic from '../../assets/img/Portfolio/marketplace.jpg'
import bulbulPic from '../../assets/img/Portfolio/crm.jpg'
import paymentGateways from '../../assets/img/Portfolio/paymentGateways.jpg'
import event from '../../assets/img/Portfolio/event.jpg'
import ocr from '../../assets/img/Portfolio/ocr.jpg'
import ml from '../../assets/img/Portfolio/ml.jpg'


export const PortfolioData = {
  "Product Contributions" : [
    {
      Link : "https://jungleworks.com/tookan/",
      PortfolioPic    : tookanPic ,
      CardColor       : 'rgba(11, 143, 11,0.3)' ,
      Title : "Tookan Product", 
      Subheader : "A Jungleworks Product.",
      Body : [
            `Managing delivery operations on a single platform can significantly enhance operational efficiency and deliver exceptional customer experiences at scale. Consolidating all delivery operations simplifies processes, reduces errors, and ensures timely delivery of products or services. By using a single platform, businesses can also better track and monitor deliveries, as well as communicate with customers in real-time, improving transparency and boosting customer satisfaction. Streamlining operations can also save time and money, allowing businesses to focus on other critical areas. Overall, centralizing all delivery operations on one platform optimizes operations and enhances customer experiences.`,
            "Project associated with Jungleworks",
            "Position - Senior Software Engineer",
          ],
      Paragraph : [
        "Implemented task auto-allocation constraints for efficient driver assignment, prioritizing high-rated drivers.",
        "Optimized pagination and customer search using seek method for Tookan SaaS Product's billions of customers data.",
        "Performed optimization of code, API, SQL, and database for improved server response and reliability, including MySQL archiving and database upgrading.",
        "Streamlined client billing process with payment automation through refactoring and optimization.",
        "Efficiently manage bulk delivery tasks through our task management solution.",
        "Maintained and monitored server performance.",
        "Designed and implemented various automations, including SQL and API performance monitoring.",
      ],
    },

    { 
      Link : "https://jungleworks.com/bulbul/",
      PortfolioPic    : bulbulPic ,
      CardColor       : 'rgba(166, 153, 68,0.3)' ,
      Title : "Bulbul CRM", 
      Subheader : "",
      Body : [
      "This platform enables efficient management of leads, sales, customers, and teams. It offers a comprehensive solution for businesses to streamline their sales processes, track customer interactions, and manage teams. With this platform, businesses can easily monitor the progress of their sales and marketing efforts, track their customer interactions and preferences, and manage their teams from a single, unified interface. The platform's user-friendly interface and powerful features make it an essential tool for any business looking to improve its sales processes and customer relationships.",
      "Project associated with Jungleworks",
      "Position -  Team Lead",
    ],
      Paragraph : [
        "Developed auto lead allocation with bot alerts for Business heads, Team Leads, and Sales Representatives from scratch.",
        "Designed and coded security controls in Sales CRM, including user login tracks, deal and contact access level control, and deal visitor logs.",
        "Performed various optimizations, such as API optimization, code quality enhancement, and speed and server load optimization.",
        "Designed and coded various features from scratch, such as Pseudo User Account, Generic Export Block, Custom role with custom ACL permission, User Session management, Single/Bulk SMS to Customers, and Calling Services.",
        "Created effective SMS, email, and ad campaigns in Sales CRM for quality lead generation.",
        "Recoded the Auth Implementation in bulbul sales CRM for improved user experience.",
        "Developed Server load balancer and auto Server reloading functionality with Alert bot for the product.",
        "Coded and integrated online accounting software like Xero and Sage to generate invoices directly from Sales CRM.",
      ],

    },
    { 
      Link : "https://jungleworks.com/yelo/",
      PortfolioPic    : yeloPic ,
      CardColor       : 'rgba(253, 174, 80,0.3)' ,
      Title : "Yelo Marketplace", 
      Subheader : "",
      Body : [
              "This platform allows you to easily create a digital ordering experience for your business without any coding knowledge. With customizable branding and menus, businesses can quickly launch their own online ordering platform or a multi-vendor marketplace. The platform's intuitive design and range of features, including order tracking and payment processing, make it simple to manage leads, sales, customers, and teams. Whether you're a small business owner or launching a large marketplace, this platform provides the tools you need to create a seamless digital ordering experience.",
              "Project associated with Jungleworks",
              "Position - Software Engineer",
            ],
      Paragraph : [
        "Expertise in multiple SaaS modules, including Online Store, Grocery, and Food Delivery apps.",
        "Integrated various SMS gateways, such as SMS Country, Unifonic, Afilnet, Buesms, Zsms, SMS91, and MekongSMS, for order and other notifications to customers.",
        "Integrated various services, including inventory management, POS, and Delivery Management Systems, in Yelo SaaS Products.",
        "Optimized the ordering system for customer billing breakdown and payment module.",

      ],


    },

    { 
      Link : "",
      PortfolioPic    : paymentGateways ,
      CardColor       :  'rgba(10, 103, 255,0.3)',
      Title : "Jungle Payment Module", 
      Subheader : "",
      Body : [
        "This microservice architecture focuses on payment module development, providing a range of payment gateway integrations like Credimax, Azul, Kanoo, Paygate, Paystack, SBM Bank, Qpay, Niubiz, and more for customer payment processing. It also includes a payment sandbox environment, which is a custom payment gateway designed to integrate any payment gateway to Yelo marketplace SaaS product with just a few simple steps. With this payment module microservice, businesses can easily manage payment processing without having to develop their own payment systems from scratch.",
        "Project associated with Jungleworks",
        "Position -  Team Lead",
      ],
      Paragraph : [
        "Expertise in coding and integrating payment gateways like Credimax, Azul, Kanoo, Paygate, Paystack, SBM Bank, Qpay, Niubiz, etc., in multiple projects for the customer payment module.",
        "Designed and coded a custom payment gateway sandbox environment to integrate any payment gateway with Yelo marketplace SaaS Product seamlessly.",
      ],


    },
   

  
  ],
  "Personal Projects" : [
    { 
      Link : "",
      PortfolioPic    : event ,
      CardColor       : 'rgba(106, 153, 168,0.3)' ,
      Title : "Online Event Management System", 
      Subheader : "",
      Body : [
      "This is a web application designed and developed using Nodejs, MySql, Express, HTML, CSS, and JavaScript. It offers a range of facilities to users and organizers, enabling them to manage events associated with any program. The system includes features such as event scheduling, attendee registration, payment processing, and account management. It also offers notifications and a user-friendly interface to make the event management process more manageable.",
      "Project associated with NIT Agartala",
    ],
      Paragraph : [
        "Web application designed and developed using Nodejs, MySql, Express, HTML, CSS, and JavaScript.",
        "Program event management system providing facilities to users and organizers.",
        "Features include event scheduling, attendee registration, and payment processing.",
        "Users and organizers can manage their account information, view upcoming events, and receive notifications.",
        "User-friendly interface designed to make event management more manageable."
      ],
    },
    { 
      Link : "",
      PortfolioPic    : ml ,
      CardColor       : 'rgba(56, 203, 150,0.3)' ,
      Title : "Suicide Statistics or Prediction in India", 
      Subheader : "",
      Body : [
        "The project is a data science and machine learning model that predicts the number of suicides that might occur in any state of India based on the last 10 year trends. The project is affiliated with IIT-BHU & e-System and utilizes technologies such as Python, Sklearn, Numpy, Pandas, Matplotlib, and Sklearn. The model uses machine learning algorithms to analyze suicide statistics from the past 10 years and predict future trends. It provides valuable insights into suicide rates and could potentially help prevent suicides by identifying high-risk areas and implementing targeted interventions. Overall, this project is an innovative use of machine learning and data science to tackle a pressing social issue.",
        "Project associated with IIT-BHU & e-System.",
      ],
      Paragraph : [
        "Worked with Python programming language for data science and machine learning.",
        "Used Scikit-learn (Sklearn) library for machine learning tasks.",
        "Worked with NumPy library for numerical computing and data analysis.",
        "Utilized Pandas library for data manipulation and analysis.",
        "Worked with Matplotlib library for data visualization purposes.",
        "Worked on implementing machine learning algorithms to analyze suicide statistics from the past 10 years and predict future trends.",
      ],

    },
    
    { 
      Link : "",
      PortfolioPic    : ocr ,
      
      CardColor       : 'rgba(187, 190, 100,0.3)' ,
      Title : "Computer Vision - Text Scanner (OCR)", 
      Subheader : "",
      Body : [
        "This project is a computer vision text scanner that uses image processing to extract text from images, even when they are skewed or rotated. The project utilizes Python as the programming language and leverages libraries such as OpenCV, Numpy, and Pytesseract to achieve its goals. By using computer vision, this project has the potential to streamline text extraction from images and improve productivity in various industries.",
        "Project associated with NIT Agartala",
        ],
      Paragraph : [
        "Worked on developing an image processing module for text extraction using computer vision.",
        "Utilized the Python programming language for the project.",
        "Worked with the OpenCV library for image processing tasks.",
        "Utilized Numpy library for numerical computing and data analysis.",
        "Worked with Pytesseract library for Optical Character Recognition (OCR) purposes.",
        "Worked on implementing algorithms to handle skewed and rotated images.",
        "Tested and validated the accuracy of the text scanner on various images.",
        "Collaborated with team members to improve the performance and functionality of the text scanner module.",
      ],

    },
    
    
   

  
  ]
}