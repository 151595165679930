import nodejs from '../img/Skills/nodejs.png';
import javascript from '../img/Skills/javascript.png';
import mongodb from '../img/Skills/mongodb.png';
import MySql from '../img/Skills/mysql.png';
import AWS from '../img/Skills/aws.png';
import C from '../img/Skills/c.png';
import CPP from '../img/Skills/cpp.png';
import Git from '../img/Skills/git.png';
import html from '../img/Skills/html5.png';
import python from '../img/Skills/python.png';
import express from '../img/Skills/express.png';
import restApi from '../img/Skills/restapi.png';
import redis from '../img/Skills/redis.png';
import css from '../img/Skills/css3.png';
import scss from '../img/Skills/sass.png';
import react from '../img/Skills/react.png';
import oops from '../img/Skills/oops.png';
import ds from '../img/Skills/DS.png';
import algo from '../img/Skills/algo.png';
import dms from '../img/Skills/dms.png';
import grafana from '../img/Skills/grafana.png';
import newRelic from '../img/Skills/newRelic.png';
import systemDesign from '../img/Skills/systemDesign.png';
import Kibana from '../img/Skills/kibana.png';


export const SkillsSet = {
  "Backend Technologies" : [
    {  
      title       : "Node",
      logo        : nodejs,
      rating      : 4.5,
      showInSlider: true
    },
    {  
      title       : "MySQL",
      logo        : MySql,
      rating      : 4.5,
      showInSlider: true

    },
    {  
      title       : "MongoDB",
      logo        : mongodb,
      rating      : 3.5,
      showInSlider: true

    },
    {  
      title       : "Redis",
      logo        : redis,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "Express",
      logo        : express,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "Rest API",
      logo        : restApi,
      rating      : 4.5,
      showInSlider: false

    },

  ],
  "Frontend Technologies" : [
   
    {  
      title       : "HTML",
      logo        : html,
      rating      : 4.5,
      showInSlider: true

    },
    {  
      title       : "CSS",
      logo        : css,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "SCSS",
      logo        : scss,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "React",
      logo        : react,
      rating      : 3.5,
      showInSlider: true

    },

  ],
  "Programming Languages" : [
    {  
      title       : "Javascript",
      logo        : javascript,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "Python",
      logo        : python,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "C",
      logo        : C,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "CPP",
      logo        : CPP,
      rating      : 4,
      showInSlider: true

    },
  ],
  "Tools" : [
    {  
      title       : "AWS",
      logo        : AWS,
      rating      : 3,
      showInSlider: false

    },
    {  
      title       : "Git",
      logo        : Git,
      rating      : 4,
      showInSlider: true

    },
    {  
      title       : "Grafana",
      logo        : grafana,
      rating      : 3.5,
      showInSlider: false

    },
    {  
      title       : "New Relic",
      logo        : newRelic,
      rating      : 3.5,
      showInSlider: false

    },
    {  
      title       : "Kibana",
      logo        : Kibana,
      rating      : 3.5,
      showInSlider: false

    },
  ],
  "Subjective" : [
    {  
      title       : "System Designing",
      logo        : systemDesign,
      rating      : 4.5,
      showInSlider: false

    },
    {  
      title       : "OOPs",
      logo        : oops,
      rating      : 4,
      showInSlider: false

    },
    {  
      title       : "Data Structures",
      logo        : ds,
      rating      : 3.5,
      showInSlider: false

    },
    {  
      title       : "Algorithms",
      logo        : algo,
      rating      : 3.5,
      showInSlider: false

    },
    {  
      title       : "DMS",
      logo        : dms,
      rating      : 4.5,
      showInSlider: false

    },
  ]
}

export default SkillsSet;