import React from 'react';
import Typed from 'typed.js';
import TypedStyle from './TypedStyle.scss'

//https://mattboldt.com/demos/typed-js/

function TypedText(props) {
  const typedRef = React.useRef(null);
  React.useEffect(() => {
    const options = {
      strings: props.TextArray,
      typeSpeed: 150,
      // backSpeed: 150,
      loop: true,
      showCursor: true,
      cursorChar: '_', 
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <span ref={typedRef}></span>
  );
}

export default TypedText;
