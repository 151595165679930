export const timelineConstant = {
  "Work Experience" : [
    {
      dateTime    : "March 2021 - Present" ,
      title       : "Jungleworks" ,
      description : [
        "Designation - Senior Software Engineer",
        "As a Senior Software Engineer and team lead at Jungleworks, I've optimized and developed new features from scratch for exciting projects like Tookan and bulbul CRM product. I have a deep understanding of software development, leadership, and communication skills. My experience would be valuable to potential tech employers.",
                   
                  ]
    },
    {
      dateTime    : "Aug 2020 - March 2021" ,
      title       : "Jungleworks" ,
      description : [
        "Designation - Software Engineer",
        "As a Software Engineer at Jungleworks, I worked on a variety of exciting projects including Yelo Marketplace, Payment Gateway, and Integration. I also integrated multiple SMS and payment gateways, such as Credimax, Azul, Kanoo, Paygate, Paystack, SBM, Qpay, and Niubiz. Additionally, I implemented Okroute for bulk SMS, AWS server with Server Load Balancer, and online accounting software like Xero and Sage. My experience has given me a diverse set of skills that would be valuable to potential employers in the tech industry.",
                   
                  ]
    },
    {
      dateTime    : "May 2020 - July 2020" ,
      title       : "Jungleworks" ,
      description : [
        "Designation - Intern",             
        "During my internship at Jungleworks, I worked as a Backend Developer on the BULBUL CRM product. I optimized numerous APIs within the CRM, worked on improving code and system speed, and gained valuable skills in database management and MySQL optimization. These experiences have been instrumental in my career as a software engineer.",
                     
                    ]
    },

  ],
  "Education" : [
    {
      dateTime    : "2016 - 2020" ,
      title       : "Bachelor of Technology" ,
      description : ["National Institute of Technology Agartala",
                    "Computer Science and Engineering",
                    "CGPA- 7.74",
                  ]
    },
    {
      dateTime    : "2013 - 2015" ,
      title       : "12th (Senior Secondary Examination)" ,
      description : ["Board of High School and Intermediate Education, Uttar Pradesh.",
                    "Board Percentage- 94.60",
                  ]
    },
    {
      dateTime    : "2012 - 2013" ,
      title       : "10th (Secondary Examination)" ,
      description : ["Board of High School and Intermediate Education, Uttar Pradesh.",
                    "Board Percentage - 88.17",
                  ]
    },
    
  ],
}

export default timelineConstant;