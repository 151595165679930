import React, {  useEffect, useState } from 'react';
import Style from './BaseLayout.module.scss';
import Navbar from '../Navbar/Navbar';
import Home from '../home/Home';
import About from '../about/About';
import Portfolio from '../portfolio/Portfolio';
import { Route, Routes } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ParticlesBg from '../particles/ParticlesBg';
import Skills  from '../Skills/Skills';
import SkillsSlider from '../Skills/SkillsSlider'
import SkillsSliderV2 from '../Skills/SkillsSliderV2'
import Footer from '../Footer/Footer';



export default function BaseLayout() {

  let [darkMode, setDarkMode] = useState(false);

  function handleToggleDarkMode() {
    let oppositeOfCurrentDarkMode = !darkMode;
    localStorage.setItem('darkMode', `${oppositeOfCurrentDarkMode}`);
    setDarkMode(oppositeOfCurrentDarkMode);
    // logGa('dark_mode_toggle', oppositeOfCurrentDarkMode ? 'dark' : 'light');
  }

  useEffect(() => {
    let detectedDarkMode = JSON.parse(localStorage.getItem('darkMode'));
    
    const osDarkModeQuery = window.matchMedia
      ? window.matchMedia('(prefers-color-scheme: dark)')
      : null;

    // uncomment when need to sync device theme [day/night]
    if (detectedDarkMode) {
      setDarkMode(detectedDarkMode);
    } 
    // else {
    //   localStorage.setItem('darkMode', `${!!osDarkModeQuery?.matches}`);
    // }

    const updateDarkMode = (e) => {
      setDarkMode(e.matches);
    };

    if (osDarkModeQuery) {
      osDarkModeQuery.addEventListener('change', updateDarkMode);
    }
  }, []);


  return (
    <Box className={darkMode ? Style.dark : Style.light}>
      <ParticlesBg darkMode={darkMode.valueOf()} />
      <Navbar darkMode={darkMode} handleClick={handleToggleDarkMode} />
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        minHeight={'100vh'}
        justifyContent={'space-between'}
        paddingTop={5}
      >
          <Routes>
          {/* <SkillsSlider/> */}
            <Route exact path={'/'} element={<> <Home darkMode={darkMode} /> <SkillsSliderV2/>  </>} />
            <Route exact path={'/about'} element={<About darkMode={darkMode} />} />
            <Route exact path={'/portfolio'} element={<Portfolio  darkMode={darkMode} />} />
            <Route exact path={'/skills'} element={<Skills  darkMode={darkMode} />} />
          </Routes>
          <Footer/>
      </Grid>
    </Box>
  );
}
