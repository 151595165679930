import React from 'react';
import './Skills.scss'; 
import SkillsSet from '../../assets/info/SkillsSet'


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

// let allSkillsLogos = Object.values(SkillsSet).flatMap(skills => skills.map(skill => skill.logo));
let allSkillsLogos = Object.values(SkillsSet).flatMap(skills => skills.filter(skill => skill.showInSlider)).map(skill => skill.logo);
allSkillsLogos = shuffleArray(allSkillsLogos)

const SkillsSlider = () => {
  return (
    <div className="image-slider-container">
      <div className="image-slider">
        {allSkillsLogos.map((skillLogo, index) => (
            <img src={skillLogo} alt={index} key={index}/>
          ))}
      </div>
    </div>
  );
};


export default SkillsSlider;
